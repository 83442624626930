<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">개인정보처리방침</template>
            <terms-component code="privacy"></terms-component>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TermsComponent,
    },
};
</script>
